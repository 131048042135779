import React, { useState, useEffect } from 'react';
import Layout from '../shared/Layout/Layout';
import Questions from '../widgets/Questions/Questions';
import { getAllFAQs } from '../api/strapi';
import { data } from '../pages-fsd/FAQPage/model/faq.data';
import HeadMaker from '../HeadMaker';
import { FAQWidget } from '../widgets';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'JS Online_FAQ',
};

const leadData = {
  question: {
    ...mainData,
    leadAction: 'Получить консультацию',
    leadBlock: 'Остались вопросы',
  },
};

function FAQPage() {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    const getData = async () => {
      try {
        const questions = await getAllFAQs(ac);
        setQuestions(questions[0].FAQ_dynamiczone);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
    return () => {
      ac.abort();
    };
  }, []);

  return (
    <Layout leadPage="F.A.Q.">
      <FAQWidget questions={questions} titleH1 />
      <Questions {...data.questions} extraLeadData={leadData.question} />
    </Layout>
  );
}

export default FAQPage;
