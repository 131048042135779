export const data = {
  questions: {
    message: 'Остались вопросы?',
    actions: {
      first: {
        title: 'Получить консультацию',
      },
      second: {
        title: 'Посмотреть Программы',
        link: '/#program',
      },
    },
  },
  seo: {
    title: 'Отвечаем на часто задаваемые вопросы о буткемпе Эльбрус',
    description:
      'Все часто задаваемые вопросы в одном месте: сколько стоит обучение, как записаться на курс, можно ли оплачивать частями и многие другие вопросы, на которые мы дали ответы. Изучайте информацию и записывайтесь на курсы профессии будущего!',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Все часто задаваемые вопросы в одном месте: сколько стоит обучение, как записаться на курс, можно ли оплачивать частями и многие другие вопросы, на которые мы дали ответы. Изучайте информацию и записывайтесь на курсы профессии будущего!',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content: 'Отвечаем на часто задаваемые вопросы о буткемпе Эльбрус',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Все часто задаваемые вопросы в одном месте: сколько стоит обучение, как записаться на курс, можно ли оплачивать частями и многие другие вопросы, на которые мы дали ответы. Изучайте информацию и записывайтесь на курсы профессии будущего!',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/05.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/05.JPG',
      },
    ],
  },
};
